<template>
    <el-dialog title="查看发票信息" width="70%" top="2vh" :close-on-click-modal="false" :visible.sync="visible">
        <div class="dialogBox">
            <el-descriptions title="发票信息" :column="2" class="b_b p_b1">
                <el-descriptions-item label="发票类型">{{info.billType == 'electron' ? '电子':(info.billType == 'paper' ? '纸质':'')}}</el-descriptions-item>
                <el-descriptions-item label="抬头类型">
                    {{$dictUtils.getDictLabel("headingType",info.headingType,'')}}
                </el-descriptions-item>
                <el-descriptions-item label="公司名称">{{info.corporateName}}</el-descriptions-item>
                <el-descriptions-item label="公司税号">{{info.companyTaxNumber}}</el-descriptions-item>
                <el-descriptions-item label="开票方">{{info.invoicingParty}}</el-descriptions-item>
                <el-descriptions-item label="发票内容">{{info.billContent}}</el-descriptions-item>
                <el-descriptions-item label="发票总额（元）">{{info.billMoney}}</el-descriptions-item>
                <el-descriptions-item label="发票申请时间">{{info.createTime}}</el-descriptions-item>
                <el-descriptions-item label="电子邮箱">{{info.userMailbox}}</el-descriptions-item>
                <el-descriptions-item label="开票状态">
                    <el-tag size="small" v-if="info.applicationStatus">
                        {{$dictUtils.getDictLabel("applicationStatus",info.applicationStatus,'')}}
                    </el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="公司电话">{{info.corporatePhone}}</el-descriptions-item>
                <el-descriptions-item label="公司地址">{{info.corporateAddres}}</el-descriptions-item>
                <el-descriptions-item label="开户行账号">{{info.accountNoOfDepositBank}}</el-descriptions-item>
                <el-descriptions-item label="公司开户行">{{info.companyDepositBank}}</el-descriptions-item>
                <el-descriptions-item label="备注">{{info.remarks}}</el-descriptions-item>
            </el-descriptions>
            <div class="b_b p_t2 p_b1">
                <el-descriptions :title="'订单信息'+ (index+1)" :column="2" class="p_b1"
                                 v-for="(item, index) in info.detail" :key="index">
                    <el-descriptions-item label="订单编号">{{item.outTradeNo}}</el-descriptions-item>
                    <el-descriptions-item label="消费时间">{{item.dissipateTime}}</el-descriptions-item>
                    <el-descriptions-item label="订单类型">{{item.orderType == 'activity' ? '活动':(item.orderType == 'server' ? '线下服务':'')}}</el-descriptions-item>
                    <el-descriptions-item label="订单金额">{{item.orderMoney}}</el-descriptions-item>
                    <el-descriptions-item label="订单名称">{{item.orderName}}</el-descriptions-item>
                </el-descriptions>
                <div v-if="info.detail && info.detail.length > 2" class="text_center pointer p_b1" @click="showAll = !showAll">{{showAll ? '收起':'显示'}}全部订单 <span :class="showAll ? 'el-icon-arrow-up':'el-icon-arrow-down'"></span></div>
            </div>
            <el-descriptions title="用户信息" :column="2" class="p_t2 p_b1">
                <el-descriptions-item label="手机号">{{info.userPhone}}</el-descriptions-item>
            </el-descriptions>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">关闭</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                info: {},
                showAll: false,
            }
        },
        methods: {
            init(row) {
                this.visible = true
                this.info = row
            },
        }
    }
</script>

<style scoped>
    .dialogBox >>> .el-descriptions-item__label {
        white-space: nowrap !important;
    }
    .dialogBox >>> .el-descriptions-item__content {
        padding-right: 40px !important;
    }
</style>
